import React, { useEffect, useState } from 'react';
import {
  isEmpty,
  applyTruncationForAllScreen,
  applyTruncationForScreen,
  findValueByKey,
  isLinkAbsolute,
  correctInternalLink,
  getImage,
  stripHtml,
  removeNbsp,
} from '../../util/DataUtil';
import { useRouter } from 'next/router';
import { Constants } from '../../util/Constants';
import Image from 'next/image';

export default function TemplateRelated({ data, title, link, primaries }) {
  if (!data || data.length === 0 || isEmpty(data[0])) {
    return '';
  }

  const router = useRouter();
  const [titles, setTitles] = useState([]);

  if (
    data &&
    Object.keys(data).length === 0 &&
    Object.getPrototypeOf(data) === Object.prototype
  ) {
    return '';
  }

  const truncate = () => {
    const titles = data.map((item) => {
      const templateTitle = findValueByKey(
        'template_title_block_type',
        item?.field_template_blocks
      )?.field_template_title?.[0]?.value;
      const heroFormattedTitle = removeNbsp(
        stripHtml(
          findValueByKey('hero_graphic_block_type', item?.field_template_blocks)
            ?.field_formatted_title?.[0]?.value
        )
      );

      if (!templateTitle && !heroFormattedTitle) {
        return '';
      }

      return [
        truncateTextByItemCount(templateTitle),
        truncateTextByItemCount(heroFormattedTitle),
      ];
    });

    setTitles(titles);
  };

  useEffect(() => {
    truncate();
  }, [data]);

  const onChipClick = (event, category) => {
    // if (typeof window !== "undefined") {
    //     localStorage.setItem('name', category[0]['name'])

    //     if(window.location.pathname !== '/resources'){
    //         router.push('/resources');
    //     }
    // }

    if (typeof window !== 'undefined') {
      // localStorage.setItem('name', category[0]['name'])
      if (window.location.pathname !== '/resources') {
        // router.push('/resources');
        router.push({
          pathname: '/resources',
          query: { category: category[0]['name'].toLowerCase() },
        });
      }
    }
  };

  const onViewAll = (event, categories) => {
    if (typeof window !== 'undefined') {
      if (!categories[0]) {
        return;
      }

      localStorage.setItem('name', categories[0]);

      if (window.location.pathname !== '/resources') {
        router.push('/resources');
      }
    }
  };

  const truncateTextByItemCount = (value) => {
    if (!value) {
      return;
    }

    let truncation;

    if (data.length == 1) {
      truncation = applyTruncationForScreen(value, 60, 60, 60, 60);
    } else if (data.length == 2) {
      truncation = applyTruncationForScreen(value, 82, 60, 60, 60);
    } else if (data.length == 3) {
      truncation = applyTruncationForScreen(value, 55, 60, 60, 60);
    } else if (data.length == 4) {
      truncation = applyTruncationForAllScreen(value, 55, 43, 45, 18, 32, 37);
    }
    return truncation;
  };

  const renderRelated = () => {
    if (!data.length) {
      return;
    }

    return data.map((item, index) => {
      return (
        <div
          key={'related-key-' + index}
          className={'div-related-content-link item'}
          onClick={(event) => {
            if (!item['url-alias']) {
              return;
            }

            event.stopPropagation();
            if (isLinkAbsolute(item['url-alias'])) {
              window.open(item['url-alias'], '_blank');
            } else {
              router.push(item['url-alias']);
            }
          }}
        >
          <div className={'img-container'}>
            {getImage(
              findValueByKey(
                'template_banner_block_type',
                item?.field_template_blocks
              )?.field_template_banner_bynder?.webimage,
              findValueByKey(
                'template_banner_block_type',
                item?.field_template_blocks
              )?.field_web_small_image?.webimage,
              findValueByKey(
                'hero_graphic_block_type',
                item?.field_template_blocks
              )?.field_hero_image?.webimage
            ) ? (
              <Image
                objectFit="center"
                layout="fill"
                alt="template-related"
                src={getImage(
                  findValueByKey(
                    'template_banner_block_type',
                    item?.field_template_blocks
                  )?.field_template_banner_bynder?.webimage,
                  findValueByKey(
                    'template_banner_block_type',
                    item?.field_template_blocks
                  )?.field_web_small_image?.webimage,
                  findValueByKey(
                    'hero_graphic_block_type',
                    item?.field_template_blocks
                  )?.field_hero_image?.webimage
                )}
              />
            ) : (
              <img alt="placeholder" src={'/images/placeholder.png'} />
            )}
          </div>
          <div className={'category'}>
            <span className={'category'}>
              {item?.field_content_type_templat
                ? item?.field_content_type_templat[0]?.name
                : item?.field_page_type
                  ? item?.field_page_type[0]?.value
                  : ''}
            </span>
            <span>
              {item?.field_time_to_read !== undefined &&
              item?.field_time_to_read[0] !== undefined
                ? `, ${item?.field_time_to_read[0]?.value}`
                : ''}
            </span>
          </div>
          <span className={'title'}>{titles[index]}</span>
          <span
            className={'span-related-content-link second-category'}
            onClick={(event) => {
              event.stopPropagation();
              onChipClick(event, [
                {
                  name: item?.field_template_category
                    ? item?.field_template_category[0]?.name
                    : '',
                },
              ]);
            }}
          >
            {item?.field_template_category
              ? item?.field_template_category[0]?.name
              : item?.field_template_tertiary_category
                ? item?.field_template_tertiary_category[0]?.name
                : ''}
            {/* {item?.field_template_category ? item?.field_template_category[0]?.name : ''} */}
          </span>
        </div>
      );
    });
  };

  return (
    <div className={'related-block inner-container'}>
      <div className={'template-related container-length-' + data.length}>
        <div className={'top'}>
          <span className={'page-sub'}>{title}</span>
          {link?.length > 0 && link[0]?.title && (
            <a
              onClick={(e) => onViewAll(e, primaries)}
              className={`a-related-content-link cta-link`}
            >
              {link[0]?.title}
              <img
                alt="arrow"
                className="more-arrow"
                src="/images/more-arrow.svg"
              />
            </a>
          )}
        </div>
        <hr />
        <div className={data.length ? `items items-${data.length}` : 'items'}>
          {renderRelated()}
        </div>
      </div>
    </div>
  );
}
